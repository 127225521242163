
.main {
	padding-top: 48px;
	padding-bottom: 16px;
}

.paper-main {
	margin-top: 16px;
}

.paper-header {
  box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
	margin-top: -24px;
	margin-bottom: 16px;
  border-radius: 3px;
  padding: 15px;
  background-color: #999999;
  background: -webkit-linear-gradient(30deg, #265dda, #004dc1);
  background: linear-gradient(60deg, #265dda, #004dc1);
	position: relative;
	top: -16px;
}
.paper-header .title {
  color: #FFFFFF;
}

